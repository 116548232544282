.Card {
  padding: 0px 10px 30px 10px;
  width: 90% !important;
  margin: 30px auto !important;
}

.Card h1 {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.GridContainer {
  margin-top: 0.5rem !important;
}

.ErrorMessage {
  color: #ff2246;
  text-align: center;
}

@media (min-width: 960px) {
  .Card {
    padding: 0px 30px 30px 30px;
    width: 80% !important;
  }
  .Card h1 {
    margin-left: 40px;
    text-align: left;
  }

  .GridContainer {
    margin: 0.5rem auto 0 auto !important;
    width: 90% !important;
  }
}
