.MyPaymentsList {
    margin: 20px 40px;
  }
  
  .EmptyStateText {
    color: #6a6a6a;
  }
  
  .EmptyStateContainer {
    text-align: center;
  }
  
  .EmptyStateImg {
    width: 80px;
    margin-top: 50px;
  }