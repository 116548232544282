.Card {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}

.News {
  position: relative;
  min-height: 140px;
}

.NoImage {
  min-height: 100px;
}

.News .Image {
  /*width: 150px;
  height: 120px;*/
  object-fit: cover;
  border-radius: 6px;
  position: absolute;
  top: -10px;
  left: 5%;
  width: 90%;
  max-width: 100%;
}

.News .Button {
  padding: 10px 20px;
  margin-bottom: 15px;
}

.NoImage .Button {
  margin-left: 30px;
}

.News .Read {
  color: #0d42fb;
}

.News .ContentContainer {
  width: 100%;
}

.News .Title {
  font-size: 18px;
  margin-bottom: 5px !important;
  padding-left: 15px;
  padding-right: 15px;
  color: #0d42fb;
}

.NoImage .Title {
  margin-top: 20px;
}

.News .Description {
  margin-top: 6px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.Date {
  color: #9e9e9e;
  margin-top: 0px;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 15px;
}

.News .DateContainer {
  width: 100%;
}

.Unread {
  box-shadow: 0 1px 8px 0 #00005c99 !important;
}

.ImageContainer {
  position: relative;
}

@media (min-width: 600px) {
  .Card {
    margin-top: 0px !important;
  }

  .News .Image {
   /* width: 150px;
    height: 120px;*/
    width: 90%;
    object-fit: cover;
    position: absolute;
    left: -10px;
    border-radius: 6px;
    top: 10px;
  }

  .News .Description {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .NoImage .Title {
    margin-top: 20px;
  }

  .News .Title {
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .News .Date {
    margin-bottom: 14px;
  }

  .News .Button {
    margin-bottom: 5px;
  }

  .NoImage .Title,
  .NoImage .Description {
    margin-left: 30px;
  }
}
