.MonthYearTitle {
  color: #0d42fb;
  font-weight: 700;
  font-size: 22px;
}

.EmptyState {
  font-size: 20px;
  text-align: center;
  width: 100%;
}