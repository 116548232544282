.Bank {
  cursor: pointer;
  border-radius: 15px !important;
}

.ImageContainer {
  height: 55px;
}

.Image {
  object-fit: contain;
  height: 55px;
  max-width: 100%;
}

.CardContent {
  padding: 12px !important;
  text-align: center;
}

.Name {
  font-size: 15px;
  color: #443e3e;
  font-family: "Proxima Nova";
  text-align: center;
  margin: 8px 0px 4px;
}

@media (min-width: 960px) {
  .Bank {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (min-width: 1600px) {
  .ImageContainer {
    height: 90px;
  }

  .Image {
    height: 90px;
  }
}