.SelectedInsuranceCard {
  width: 80% !important;
  margin: 0 auto;
  padding: 0px 30px 30px 30px;
}

.InsuranceImage {
  background-size: cover;
  margin-top: 25px;
  width: 50%;
}

.CoverageName {
  font-size: 1rem;
  text-align: center;
}

.CardTitleCustom {
  text-align: center;
}

.ConfirmationDialogButton {
  margin-top: 20px !important;
}

.ConfirmationDialogPaperRoot {
  text-align: center;
  max-width: 400px;
  line-height: 25px;
}

.InsuranceImageContainer {
  text-align: center;
  width: 100%;
}

.EmptyState {
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.InsuranceDetailsItem {
  align-items: center;
  display: flex;
}

.InsuranceDetailsItem img {
  height: 29px;
  margin-right: 8px;
  width: 29px;
  max-width: 100%;
}

.InsuranceDetailsText {
  font-size: 1rem;
  color: #0d42fb;
}

.InfoButton {
  background: #d2d2d2;
  border-radius: 8px;
  margin-right: 15px !important;
  margin-top: 40px !important;
}

.ButtonsContainer {
  text-align: center;
}

.TermsAndConditionsButton {
  padding-left: 37px;
  margin: 1rem 0;
}

.TermsAndConditionsButton span {
  cursor: pointer;
}

img {
  max-width: 100%;
}

@media (min-width: 960px) {
  .InsuranceImageContainer {
    width: 30%;
  }

  .InsuranceImage {
    width: 70%;
  }
}
