.Input, .HelperText {
  font-family: 'Proxima Nova' !important;
}

.Dense {
  margin-top: 16px !important;
}

.Search {
  display: block !important;
}

.Search > div {
  position: unset;
  width: 100%;
}

.FullWidth {
  width: 100%;
}

.HelperText {
  margin: 5px auto 0px auto !important;
}

.Textarea {
  width: 90%;
}

.Uppercase input {
  text-transform: uppercase;
}

@media (min-width: 960px) {
  .TextField, .HelperText {
    /*width: 80%;*/
  }
}