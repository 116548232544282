.Card {
  padding: 0px 30px 30px 30px;
  width: 80% !important;
  margin: 30px auto !important;
}

.DialogPaper {
  height: 100%;
  max-width: 400px !important;
}

.ShowMoreButton {
  margin: 40px auto 5px !important;
  display: block !important;
}

.OKButton {
  margin: 10px auto;
}

.Message {
  font-size: 20px;
}

.InfoTextContainer {
  text-align: center;
  margin-bottom: 50px;
}

.InfoText {
  background: #0d42fb;
  border-radius: 20px;
  padding: 18px 30px !important;
  display: inline-block;
  margin: 0 auto;
  color: white;
  margin-top: 25px;
}