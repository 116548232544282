.EmptyState {
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.GridContainer {
  margin-top: 20px !important;
} 

@media (min-width: 500px) and (max-width: 960px) {
  .BankGridItem { 
    max-width: 33% !important;
    flex-basis: 33% !important;
  }
}

@media (min-width: 960px) {
  .BankGridItem { 
    max-width: 25% !important;
    flex-basis: 25% !important;
  }
}