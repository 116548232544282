.TermsContainer {
  height: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.AcceptTermsButtonContainer {
  margin: 1rem auto;
  width: 10%;
}

.AcceptTermsButton {
  width: 100% !important;
}
