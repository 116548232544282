.DebitProviderListItem {
  cursor: pointer;
}

.DebitProviderSelected {
  border: 2px solid #0d42fb;
  position: relative;
}

.DebitProviderImage {
  height: 85px;
  max-width: 100%;
}

.CheckIcon {
  color: #0d42fb;
  right: 5px;
  top: 5px;
  position: absolute;
}

.CardContent {
  padding: 12px !important;
  text-align: center;
}

.Name {
  font-size: 16px;
  color: #9f9f9f;
  font-family: "Proxima Nova";
  text-align: center;
  margin: 8px 0px 4px;
}
