.CardTitle {
  color: #0d42fb;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-top: 40px;
}

@media (min-width: 960px) {
  .CardTitle {
    margin-left: 40px;
  }
}
