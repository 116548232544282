.CardTitle {
  margin: 0.5rem 0;
  width: 100%;
  text-align: center;
}

.AddAccount {
  width: 80% !important;
  margin: 0 auto;
  padding: 0px 30px 30px 30px;
}

.RequirementsContainer {
  margin-top: 2.5rem;
}

.ConfirmationDialogButton {
  margin-top: 20px !important;
}

.PrimaryConfirmationDialogButton {
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.PrimaryConfirmationDialogPaperRoot {
  max-width: 400px;
  line-height: 25px;
}

.ConfirmationDialogPaperRoot {
  text-align: center;
  max-width: 400px;
  line-height: 25px;
}


.InsuranceImage {
  background-size: cover;
  margin-top: 12px;
  width: 50%;
  max-width: 100%;
}

.InsuranceImageContainer {
  text-align: center;
}

.DialogButtonsContainer {
  text-align: right;
}

@media (min-width: 600px) {
  .InsuranceImage {
    background-size: cover;
    margin-top: 25px;
    width: 50%;
  }
  
  .InsuranceImageContainer {
    width: 50%;
    position: absolute;
    right: 0px;
  }
}

@media (min-width: 1100px) {
  .InsuranceImageContainer {
    width: 35%;
  }
}

@media (min-width: 1900px) {
  .InsuranceImage {
    width: 35%;
  }
}