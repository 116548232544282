.RutText {
  color: #949494;
  font-size: 8px;
}

.OfficeInfoText {
  display: block;
  color: rgb(71, 71, 71);
  font-size: 14px;
  margin-top: 5px;
}

.OfficeInfoContainer {
  padding: 0px 11px;
}

.OfficeTitle {
  font-weight: 700; 
  display: block;
}

.SerialNumberText {
  color: #ff2246;
  font-weight: 700;
  text-align: right;
  margin-right: 10px;
}

.DigitalReceipt .Divider {
  height: 1px;
  background: #ccc;
}

.DigitalReceipt .ReceiptType {
  text-transform: uppercase;
  margin-top: 8px;
}

.DigitalReceipt .ReceiptDetailsText, .ReceiptType {
  font-weight: 600;
}

.ReceiptDetailsText {
  margin-bottom: 5px;
  font-size: 16px;
}

.EticketButton {
  margin: 25px auto 0px !important;
  display: block !important;
}

.TotalContainer {
  margin-top: 12px; 
  margin-bottom: 12px;
}

.TotalValueText {
  float: left;
  font-weight: 600;
}

.TotalValue {
  float: right;
  font-weight: 600;
}

.Message {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: 25px;
}