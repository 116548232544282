.Card {
  padding: 0px 30px 30px 30px;
  width: 80% !important;
  margin: 30px auto !important;
}

.EmptyState {
  margin-top: 30px;
  font-size: 20px;
  text-align: center;
}