.Card {
  padding: 0px 30px 30px 30px;
  width: 80% !important;
  margin: 30px auto !important;
}

.Card h1 {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.GridContainer {
  margin-top: 0.5rem !important;
}

.Paper {
  cursor: pointer;
  padding: 1rem !important;
}

.Paper img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

img {
  max-width: 100%;
}

.Error {
  text-align: center;
  font-size: 20px;
}

@media (min-width: 960px) {
  .Card h1 {
    margin-left: 40px;
    text-align: left;
  }

  .GridContainer {
    margin: 0.5rem auto 0 auto !important;
    width: 90% !important;
  }
}
