.FieldTitle {
    padding-left: 12px;
  }
  
  .GridItem {
    padding-bottom: 10px;
  }
  
  .Icon {
    color: #0d42fb;
  }
  
  img {
    max-width: 100%;
  }
  
  .ImageContainer {
    text-align: center;
    padding-bottom: 20px;
  }
  
  .PaymentFieldText {
    color: #6a6a6a;
    font-family: "Proxima Nova";
    font-size: 16px;
  }
  
  .PaymentFieldValue {
    color: #6a6a6a;
    font-family: "Proxima Nova";
    font-weight: 600;
    font-size: 16px;
  }
  
  .DetalleCuota{
    border: 1px solid #6a6a6a;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
  }