.RequirementsForm {
  padding: 0px 10px;
}

.ConfirmButton {
  margin-top: 40px !important;
}

.ButtonsContainer {
  text-align: center;
}

.InfoButton {
  background: #d2d2d2;
  border-radius: 8px;
  margin-right: 15px !important;
  margin-top: 40px !important;
}

@media (min-width: 960px) {
  .ConfirmButton {
    width: 20%;
    margin: 50px auto !important;
  }

  .InfoButton {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin-right: 28px !important;
  }
}
