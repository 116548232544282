.Card {
  padding: 30px 0px 30px 0px;
  width: 90% !important;
  margin: 25px auto !important;
}

.CreditLine {
  width: 90% !important;
  margin: 0px auto !important;
}

.CardDetails {
  background: #ebe9e9;
  width: 90% !important;
  padding: 10px 20px;
}

.Summary,
.Account {
  text-align: center;
}

.Summary p {
  display: inline-block;
  color: #0d42fb;
  font-size: 28px;
  font-weight: 200;
  margin: 0px;
}

.Summary h2 {
  color: #0d42fb;
  display: inline-block;
  font-size: 2.5rem;
  margin: 0px;
  margin-top: 20px;
}

.Account p,
.Account span {
  color: #0d42fb !important;
}

.CardButton {
  text-transform: capitalize !important;
}

.AccountStatement {
  width: 60%;
  color: #0d42fb !important;
  border: 1.5px solid #0d42fb !important;
  display: block !important;
  margin: 40px auto 15px auto !important;
  padding: 7px !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.CurrentMovements {
  width: 60%;
  color: white !important;
  display: block !important;
  margin: 0px auto 10px auto !important;
  padding: 7px !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.GridItemPadding {
  padding-left: 10px;
}

.CardDetailsText {
  font-size: 15px;
  font-weight: 200;
  color: #828282;
  margin-bottom: 4px !important;
}

.CardDetailsValue {
  margin-top: 10px;
  color: #616161;
}

.CardDetailsValueFirst {
  font-weight: 600;
  font-size: 20px;
}

.CardDetailsDivider {
  width: 100%;
  background: #f3f3f3;
  height: 1px;
}

.Details {
  border: 2px solid #0d42fb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  margin: 0 auto;
  max-width: 230px;
}

.Details .Title {
  color: #0d42fb;
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.Details .CashTotal {
  color: #0d42fb;
  display: block;
  font-size: 1.25rem;
  margin: 0;
}

.Details .Icon {
  animation: backgroundFill 4s ease-in-out forwards;
  background-image: linear-gradient(to top, #fed900 50%, transparent 50%);
  background-size: 200% 200%;
  border-radius: 50%;
  margin: 1rem;
  max-width: 200px;
}

@keyframes backgroundFill {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}

.Details .CashUsed {
  color: #0d42fb;
  border-top: 2px solid #0d42fb;
  padding: 0.25rem 1rem;
}

.Details .CashAvailable {
  background: #0d42fb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  padding: 0.25rem 1rem;
}

.Details .CashUsed p span,
.Details .CashAvailable p span {
  font-size: 1.25rem;
  margin-left: 0.5rem;
  font-weight: bold;
}

.Details .CashUsed p,
.Details .CashAvailable p {
  font-size: 1rem;
  margin: 0;
}

.Error {
  text-align: center;
}

.Error p {
  margin: 0;
  font-size: 20px;
}

.Error p + p {
  margin-top: 1rem;
}

@media (min-width: 600px) {
  .Summary {
    /*margin-bottom: 1.5rem;*/
    /* margin-left: 20px;*/
    text-align: left;
  }

  .Summary h2 {
    margin-top: 0px;
    margin-left: 20px;
  }

  .Account {
    text-align: left;
  }

  .Details {
    max-width: 220px;
  }

  .DetailsContainer > div + div {
    margin: 0;
  }

  .CardDetails {
    width: 90% !important;
    padding: 0px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .CreditLineIcon {
    margin-top: 15px;
  }

  .CardDetails {
    width: 91% !important;
    padding: 0px 30px;
  }
}
