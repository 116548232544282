.LoanTitle {
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}

.DetailTitle {
  margin-top: 12px;
  float: left;
  font-weight: 600;
  color: #6a6a6a;
  font-size: 17sp;
}

.Value {
  float: right;
  margin-top: 12px;
  font-weight: 600;
  color: #6a6a6a;
  font-size: 17sp;
}

.TotalAmount {
  color: black;
}

.Status {
  color: #6a6a6a;
  text-align: center;
  margin-top: 0px !important;
}

.BranchOfficesButton {
  margin: 20px auto !important;
}

.Arrears {
  color: #761818;
}