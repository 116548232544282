.Type {
  font-weight: 600;
}

.AmountText {
  color: #3a444d;
  font-weight: 600;
  font-size: 18px;
}

.DateText {
  color: #5c5c5c;
}

.DigitalReceiptsListItem {
  cursor: pointer;
}

.DigitalReceiptsListItem .Divider {
  width: 100%;
  height: 1px;
  background: #f1eeee;
}

@media (min-width: 960px) {
  .DigitalReceiptsListItem .Divider {
    width: 90%;
  }
} 