.CreditHistoryListItem {
  cursor: pointer;
  padding: 0.1rem 1rem !important;
}

.CreditHistoryListItem .Status {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: black;
}

.CreditHistoryListItem .Date, .Account {
  font-size: 16px;
  color: #6a6a6a;
  margin-top: 0px !important;
  margin-bottom: 12px;
}

.CreditHistoryListItem .Amount {
  font-size: 18px;
  color: #3A444D;
  font-weight: 600;
  text-align: right;
}