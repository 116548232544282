.SelectedShopCard {
  width: 97% !important;
  margin: 0 auto;
  padding: 2px;
  height: 800px;
}

.SelectedShopContainer {
  height: 100%;
}
