.HelpImage {
  max-width: 400px;
  display: block;
  margin: 12px auto;
}

.LetterIcon {
  max-width: 32px;
  margin-right: 15px;
}

.UserInfoIcon {
  font-size: 35px !important;
  margin-right: 15px;
  color: #0d42fb;
}

.Button {
  margin: 15px auto 20px auto !important;
}

.AddDebitForm {
  width: 80%;
  margin: 0 auto;
}