.MyPaymentsListItem {
  position: relative;
  cursor: pointer;
}

.ConfirmedIcon {
  color: #3cc244;
}

.CancelledIcon {
  color: #0d42fb;
}

.StatusContainer {
  margin-top: 4px;
}

.Status {
  display: inline-block;
  margin-left: 5px;
  color: #6a6a6a;
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 16px;
}

.AmountText {
  color: #6a6a6a;
  font-family: "Proxima Nova";
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}

.DateText {
  color: #6a6a6a;
  font-family: "Proxima Nova";
  font-size: 16px;
  display: inline;
  margin-left: 8px;
}

.DateIcon {
  color: #6a6a6a;
}

.Divider {
  width: 2px;
  height: 80%;
  background: #ccc;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 10%;
}

img {
  max-width: 100%;
}