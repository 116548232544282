.ConfirmationDialogButton {
  margin: 10px auto 0px !important;
}

.ConfirmationDialogText {
  margin-top: 2px;
  font-size: 17px;
}

.CancelButton {
  margin-top: 7px !important;
  margin-right: 12px !important;
}

.ConfirmationDialogPaperRoot {
  padding: 10px 30px;
  text-align: right;
}
