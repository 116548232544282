@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  src: url('./assets/fonts/proxima-nova-light.otf')
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  src: url('./assets/fonts/proxima-nova-reg.otf')
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  src: url('./assets/fonts/proxima-nova-sbold.otf')
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 700;
  src: url('./assets/fonts/proxima-nova-bold.otf')
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova' !important;
  background: #0d42fb;
}

/** Avoid image border in IE 10 **/
img {
  border: none;
}

/** Avoid images title shown while loading in Firefox **/ 
img:-moz-loading {
  visibility: hidden;
}