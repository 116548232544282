.AddDebit {
  text-align: center;
}

.Description {
  font-size: 20px;
  color: #0d42fb;
  margin: 35px 30px 10px;
}

.GridContainer {
  width: 80% !important;
  margin: 0 auto;
}

.SpinnerContainer {
  padding: 100px;
}

.ErrorMessage {
  color: #ff2246;
  text-align: center;
}