.Divider {
  height: 1px;
  background: #eaeaea;
  margin-bottom: 3px;
}

.NewsList {
  width: 85% !important;
  margin: 10px auto 0px auto !important;
}

.NewsListItem {
  /*padding: 0px !important;*/
}

.NewsList .EmptyState {
  font-size: 20px;
  text-align: center;
  width: 100%;
}