.Card {
  padding: 0px 30px 30px 30px;
  width: 80% !important;
  margin: 30px auto !important;
}

.InputContainer {
  width: 90%;
}

.MovementsContainer {
  padding: 20px;
}

.EmptyState {
  font-size: 20px;
  text-align: center;
}

.FiltersContainer {
  margin-top: 20px;
}

.SubmitButtonContainer {
  text-align: center;
}

.Button {
  padding: 6px 12px !important;
  margin-left: 5px !important;
}

.AccountStatementCard {
  width: 85%;
  margin: 0 auto;
  padding: 2px;
  height: 800px;
}

.AccountStatementContainer {
  height: 100%;
}

.SpinnerContainer {
  margin: 50px 0;
}

.Table {
  margin-top: 25px;
  overflow-x: auto;
  min-width: 650px;
  width: 90%;
}

.TableCell {
  min-width: 100px;
  padding: 8px 30px 8px 8px !important;
}

@media (min-width: 960px) {
  .SubmitButtonContainer {
    text-align: left;
  }

  .TableCell {
    min-width: 0px;
  }
/*
  .Table {
    margin: 40px;
  }*/
}
