.Card {
  padding: 0px 30px 60px 30px;
  width: 80% !important;
  margin: 30px auto !important;
}

.Card h1 {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
}
