.Card {
  padding: 30px;
  width: 80% !important;
  margin: 30px auto !important;
}

.Card > h1 {
  margin-top: 10px;
}

.CardBody {
  text-align: center;
}

.CardBody div + p {
  width: auto !important;
}

.CardFooter {
  margin: 0 15px 0px !important;
}

.CardFooterContainer {
  width: 100%;
  text-align: center;
}

.RequestButton {
  margin: 15px auto 20px auto !important;
}

.Feedback {
  color: black;
  font-weight: 500;
  font-size: 19px;
  margin: 20px auto;
  text-align: center;
}
