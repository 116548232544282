.Account {
  padding: 0px 50px 0px 55px;
}

.Card {
  margin-bottom: 0px !important;
 /* margin-top: 0px !important;*/
  border-radius: 15px !important;
  box-shadow: 7px 7px 24px 1px rgba(0, 0, 0, 0.14);
}

.CardBody {
  text-align: center;
}

.ProfileInfoCardBody {
  padding-left: 42px;
  padding-right: 35px;
  padding-top: 35px;
  padding-bottom: 35px;
  padding: 0.9375rem 28px 0.9375rem 40px !important;
}

.UpdateButton {
  margin: 15px auto 20px auto!important;
}

.PersonName {
  font-size: 15px;
  color: white;
  text-align: center !important;
  margin-top: 15px !important;
}

.PersonInfoItemContainer {
  padding: 10px 0px;
}

.PersonInfoContainer {
  position: relative;
  margin-top: 30px;
}

.PersonInfoIcon {
  margin-right: 20px;
  font-size: 28px;
  margin-bottom: -6px;
}

.PersonInfoText {
  font-size: 17px;
  font-weight: 500;
  color: #888;
  text-align: right;
  display: block;
}

.Feedback {
  font-weight: 500;
  text-align: center;
  font-size: 19px;
  color: black;
  margin: 0px auto 20px auto;
}

.CardFooter {
  margin: 0 15px 0px !important;
}

.CardFooterContainer {
  width: 100%;
  text-align: center;
}

.ClientProfileImageContainer {
  background: #0d42fb;
  z-index: 15;
  border-radius: 15px;
  box-shadow: 5px 5px 6px 1px rgba(4, 96, 174, 0.14);
  text-align: center;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  margin-bottom: -25px;
}

.ProfileImage {
  width: 38%;
  vertical-align: middle;
  margin-top: 5%;
}

@media(min-width: 960px) {

  .PersonInfoContainer {
    margin-left: 50px;
    margin-top: 0px;
  }

  .PersonalDataContainer {
    width: 65%;
    margin-top: 20px;
  }

  .ClientProfileImageContainer {
    position: absolute;
    top: -15px;
    left: -15px;
    bottom: 20px;
    margin-bottom: 0;
  }
}