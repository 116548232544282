.TabLabel {
  font-size: 14px !important;
}

.TabsRoot {
  min-width: 120px !important;
  width: 50% !important;
  height: 54px !important;
  background: #e3e9e9 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  margin-top: 0px !important;
  margin-bottom: 35px !important;
  border-bottom: none !important;
  border-right: 1px solid #ddd !important;
  color: #0d42fb !important;
}

.TabSelected {
  background: white !important;
  box-shadow: 0 0.1rem 1rem 0.1rem rgba(0, 0, 0, 0.1) !important;
  z-index: 10 !important;
  border-bottom: 4px solid #0d42fb !important;
  border-right: none !important;
  color: #0d42fb !important;
}

.TabImg {
  margin-right: 15px !important;
  margin-bottom: -3px !important;
  display: none !important;
  max-width: 100%;
}

@media (min-width: 600px) {
  .TabImg {
    display: inline !important;
  }
}

@media (min-width: 960px) {
  .Tabs {
    margin-top: 0px !important;
  }

  .TabLabel {
    font-size: 21px !important;
  }

  .TabsRoot {
    min-width: 295px !important;
    height: 70px !important;
    margin-top: 50px !important;
  }

  .TabSelected {
    box-shadow: 0 0.6rem 1rem 0.2rem rgba(0, 0, 0, 0.2) !important;
  }
}
