.CreditLines h2 {
  font-weight: 600;
  color: #0d42fb;
  margin-top: 30px;
  font-size: 28px;
  margin-bottom: 16px;
}

.Subtitle {
  font-size: 20px;
  font-weight: 200;
  display: inline-block;
  margin-top: 0px;
}

.SubtitleValue {
  margin-left: 10px;
  font-size: 22px;
  font-weight: 600;
}

.SubtitleContainer p,
.SubtitleContainer span {
  color: #0d42fb !important;
}

.CreditLineContainer {
  background: #0d42fb;
  border-radius: 6px;
  color: #ffffff;
  color: #ffffffe3;
  width: 100%;
  margin: 0 auto;
}

.CreditLineIconBackground {
  /*background: #01447b;*/
  display: none;
}

.CreditLineIconContainer {
  margin: 0 auto !important;
}

.CreditLineDivider {
  display: none;
}

.CreditLineIcon {
  margin-top: 30px;
  max-width: 100%;
}

.CreditLineItemText {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 12px;
}

.CreditLineItemValue {
  font-weight: 500;
  font-size: 4vw;
  margin: 0px;
}

.LinearProgressContainer {
  background: #2a3b94;
  background: #01447b85;
  padding: 14px 14px 14px 0px;
  border-radius: 6px;
}

.LinearProgress {
  border-radius: 5px;
  height: 7px !important;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #013b6b !important;
}

.ProgressUsedText {
  font-size: 16px;
  font-weight: 200;
}

.ProgressUsedValue {
  font-weight: 600;
  color: #fed900;
  margin-left: 6px;
}

.LimitContainer {
  display: inline-block;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}

.UsedProgressContainer {
  text-align: center;
}

@media (min-width: 600px) and (max-width: 960px) {
  .CreditLineIcon {
    margin-top: 15px;
  }
}

@media (min-width: 960px) {
  .CreditLineContainer {
    width: 90%;
    margin: 0px;
  }

  .CreditLineDivider {
    display: block;
    width: 1px;
    background: #ffffff61;
    height: 20px;
    margin-left: 25px;
    margin-top: 20px;
  }

  .CreditLines h2 {
    margin-top: 50px;
  }

  .CreditLineIconContainer {
    position: relative;
    margin: 0 !important;
  }

  .CreditLineIcon {
    position: absolute;
    left: 40%;
    top: 30px;
    margin-top: 0px;
  }

  .LimitContainer {
    padding-left: 40px;
  }

  .ProgressUsedText {
    margin-left: 30px;
  }

  .UsedProgressContainer {
    text-align: left;
  }

  .CreditLineIconBackground {
    display: block;
    width: 90%;
    min-height: 100px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
  }

  .CreditLineItemValue {
    font-size: 2vw;
  }
}

@media(min-width: 1200px) {
  .CreditLineItemValue {
    font-size: 1.7vw;
  }
}