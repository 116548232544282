.Paper {
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Paper img {
  display: block;
  margin: 0 auto;
  width: 50%;
  max-width: 100%;
}

.Name {
  text-align: center;
}

.InsuranceSelected {
  border: 2px solid #0d42fb;
  position: relative;
}

.CheckIcon {
  color: #0d42fb;
  right: 5px;
  top: 5px;
  position: absolute;
}

@media (min-width: 960px) {
  .Paper {
    min-height: 150px;
  }
}