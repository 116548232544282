.Auth {
  width: 100%;
  height: 100vh;
}
.AuthFormContainer{
  display: table;
  width: 100%;
  height: 100%; 
}

.AuthFormSubContainer{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.AuthForm {
  margin-left: auto;
  margin-right: auto;
  max-width: 55%;
  background: white;
  padding: 25px;
  text-align: center;
  margin-bottom: 20px;
}

.SubmitButton {
  width: 100%;
  padding: 10px 40px !important;
  margin: 25px auto 0px auto !important;
  display: block !important;
}

.Logo {
  cursor: pointer;
  width: 200px;
  margin: 20px auto 15px auto;
  max-width: 100%;
}

.ErrorMessage {
  color: #ff2246;
}

.DialogPaper {
  width: 100%;
  height: 100%;
  max-width: 500px;
} 

.TermsAndConditonsContainer {
  height: 100%;
}

.InsertCodeInput {
  border: transparent;
  border-bottom: 4px solid #d6d6d6;
  width: 50px;
  font-size: 40px;
  text-align: center;
  outline: none;
  margin-right: 8px;
}

.InsertCodeInputActive {
  border-bottom: 4px solid #0d42fb;
}

.Welcome{
  padding-bottom: 15px;
  font-size: 2.0rem;
  margin-top: 10px;
  color: #1a1818;
}

.Registro{
  padding-bottom: 15px;
  font-size: 1.5rem;
  margin-top: 10px;
  color: #1a1818;
}

@media (min-width: 960px) {
  .AuthForm {
    max-width: 30%;
    padding: 40px;
  }

  .Logo {
    margin: 10px auto 15px auto;
  }

  .Form {
    width: 80%;
    margin: 0 auto;
  }

  .Welcome{
    font-size: 2.5rem;
  }

  .Registro{
    font-size: 2rem;
  }
}