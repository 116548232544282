.DetailTitle {
  margin-top: 12px;
  float: left;
  font-weight: 500;
  color: rgb(71, 71, 71);
}

.Values {
  float: right;
  margin-top: 12px;
  color: rgb(71, 71, 71);
}