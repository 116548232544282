.MessageTop {
  font-size: 25px;
  color: #fed900;
}

.MessageBottom {
  color: white;
  font-size: 22px;
}

.SuccessDialogPaperRoot {
  background-color: #0d42fb !important;
  text-align: center;
  padding: 10px 30px;
}

.SuccessImage {
  width: 80px;
}

.OkButton {
  color: #0d42fb !important;
  background: white !important;
  padding: 10px 39px !important;
  margin-top: 10px !important;
}