.ReactDatePicker {
  text-align: center;
}

.ReactDatePicker > div {
  display: block !important;
  z-index: 1000 !important;
}

.ReactDatePicker:nth-child(2) {
  left: 50px;
}

.ReactDatePicker > div > div {
  width: 100%;
}

.ReactDatePicker > div > div > div {
  width: 100%;
}

.Dense {
  margin-top: 16px !important;
}

.Textarea {
  width: 90%;
}
