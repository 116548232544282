.Card {
  padding: 0px 30px 30px 30px;
  width: 80% !important;
  margin: 30px auto !important;
}

.InputContainer {
  width: 90%;
}

th {
  color: #0d42fb !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.TableResponsive {
  width: 100%;
  overflow-x: auto;
}

.FiltersContainer {
  margin-top: 20px;
}

.SubmitButtonContainer {
  text-align: center;
}

.EmptyState {
  font-size: 20px;
  text-align: center;
}

@media (min-width: 960px) {
  .SubmitButtonContainer {
    text-align: left;
  }
}