.Image {
  max-width: 70px;
  border-radius: 14px;
}

.GridContainer {
  padding: 20px;
  padding-top: 10px;
}

.BankAccountText {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.Name {
  margin-top: 5px;
}

.Account {
  font-weight: 600;
  font-size: 18px;
}

.AccountType {
  color: #6a6a6a;
}

.DeleteIcon {
  position: absolute !important;
  left: -15px;
  top: 58px;
  z-index: 1000;
  background-color: #ff2246 !important;
  padding: 3px !important;
  color: white !important;
}

.DeleteIcon svg {
  font-size: 18px;
}

.InfoGridItem {
  position: relative;
  padding-right: 10px !important;
  padding-left: 40px !important;
}

@media (min-width: 960px) {
  .Image {
    max-width: 85px;
  }

  .Name {
    margin-top: 10px;
  }

  .InfoGridItem {
    padding-left: 20px !important;
  }
}

@media (min-width: 1700px) {
  .Image {
    max-width: 100px;
  }
}