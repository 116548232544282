.MonthYearTitle {
  color: #3A444D;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 5px !important; 
}

.EmptyState {
  font-size: 20px;
  text-align: center;
  width: 100%;
}
