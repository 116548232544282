.InfoTextContainer {
    text-align: center;
  }
  
  .InfoText {
    background: #0d42fb;
    border-radius: 20px;
    padding: 18px 30px !important;
    display: inline-block;
    margin: 0 auto;
    color: white;
    margin-top: 25px;
  }

  .ContenedorPaganza{
    text-align: center;
    font-size: 20px;
    background-color: white;
    padding: 30px;
  }