.Card {
  padding: 0px 30px 30px 30px;
  width: 80% !important;
  margin: 30px auto !important;
}
  
.Card h1 {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.ErrorMessage {
  color: #ff2246;
  text-align: center;
}

.EmptyState {
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.ConfirmationDialogButton {
  margin-top: 20px !important;
}

.ConfirmationDialogPaperRoot {
  text-align: center;
  max-width: 400px;
  line-height: 25px;
}

.ButtonsContainer {
  text-align: center;
  margin: 30px 0px;
}

.Button {
  border-radius: 8px;
  margin: 5px 10px !important;
}

.InfoButton {
  background: #d2d2d2;
}

.DeleteAccountButton {
  position: absolute !important;
  right: 30px;
  top: 35px;
}

@media (min-width: 960px) {
  .Button {
    width: 20%;
    margin: 50px 10px !important;
  }

  .ButtonsContainer {
    margin: 0px;
  }
}
