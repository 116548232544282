.Active {
  background: white;
  box-shadow: 0 12px 20px -10px rgba(4, 96, 174, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
    0 7px 8px -5px rgba(4, 96, 174, 0.2);
  transition: all 100ms linear;
  border-radius: 4px;
  width: 90%;
  margin: 0 auto;
  color: #0d42fb !important;
}

.Active .ItemText {
  color: #0d42fb !important;
}

.Active svg {
  color: #0d42fb !important;
}

.ChildItem {
  margin-left: 20px;
}

.ItemLink {
  display: flex !important;
  color: #fff !important;
}

img {
  max-width: 100%;
}

.ItemText {
  display: inline-block;
}

.BadgeContainer {
  display: inline-block;
  background: white;
  color: #0d42fb;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  padding: 0px 2px 4px 3px;
  position: absolute;
  top: 11px;
}

.Badge {
  font-size: 0.8rem;
  text-align: center;
}

.Pulse {
  animation: Pulse 3s infinite;
  height: 40px;
  position: absolute;
  width: 88%;
  border-radius: 5px;
  margin-bottom: 5px;
  top: 5px;
  left: 6px;
}

@-webkit-keyframes Pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  }
  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes Pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  }
  70% {
    -moz-box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
