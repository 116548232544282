.TextField,
.Textarea,
.HelperText {
  width: 90%;
}

.Input,
.HelperText {
  font-family: 'Proxima Nova' !important;
}

.Recaptcha {
  width: 98%;
  margin: 0 auto;
  margin-top: 15px;
}

.Dense {
  margin-top: 16px !important;
}

.Textarea {
  width: 90%;
}

.Checkbox {
  margin-left: 5% !important;
}

.CustomCheckbox {
  display: flex;
  align-items: center;
}

.SelectContainer {
  width: 80%;
}

.Invalid {
  border: 1px solid #ff2246;
  background-color: salmon;
}

.ValidationError {
  color: #ff2246;
  margin: 5px 0;
}

.OutlinedSelect {
  width: 100% !important;
}

.FullWidth {
  width: 100%;
}

.HelperText {
  margin: 5px auto 0px auto !important;
}

@media (min-width: 960px) {
  .TextField,
  .HelperText {
    width: 80%;
  }

  .Recaptcha {
    width: 80%;
  }

  .RadioButton {
    width: 80%;
    margin: 10px auto 0px;
  }
}
